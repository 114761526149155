import React from 'react';
import {Route, Switch} from 'react-router';
// import ProtectedRoute from './protectedRoute';
// import Login from '../auth/Login';
// import AppScreen from './AppScreen';
// <Route exact path="/login" name="Login Page" component={Login} />
// <ProtectedRoute path="/" component={AppScreen} />

import StartPage from '../startpage'

class App extends React.Component{
  render() {
    return (
      <Switch>
				<Route exact path="/" component={StartPage} />
      </Switch>
    );
  }
}

export default App;
