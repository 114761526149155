// LIST
export const LIST_SUCCESS = 'ARTICLE/LIST/SUCCESS';
export const LIST_FAILURE = 'ARTICLE/LIST/FAILURE';
export const LIST_REQUEST = 'ARTICLE/LIST/REQUEST';
// DETAIL
export const DETAIL_SUCCESS = 'ARTICLE/DETAIL/SUCCESS';
export const DETAIL_FAILURE = 'ARTICLE/DETAIL/FAILURE';
export const DETAIL_REQUEST = 'ARTICLE/DETAIL/REQUEST';
// CREATE
export const CREATE_SUCCESS = 'ARTICLE/CREATE/SUCCESS';
export const CREATE_FAILURE = 'ARTICLE/CREATE/FAILURE';
export const CREATE_REQUEST = 'ARTICLE/CREATE/REQUEST';
// EDIT
export const EDIT_SUCCESS = 'ARTICLE/EDIT/SUCCESS';
export const EDIT_FAILURE = 'ARTICLE/EDIT/FAILURE';
export const EDIT_REQUEST = 'ARTICLE/EDIT/REQUEST';
// REMOVE
export const REMOVE_SUCCESS = 'ARTICLE/REMOVE/SUCCESS';
export const REMOVE_FAILURE = 'ARTICLE/REMOVE/FAILURE';
export const REMOVE_REQUEST = 'ARTICLE/REMOVE/REQUEST';
