import {get} from 'lodash';
import * as filterTypes from './actions/FilterActionTypes';

const initUserState = {
  selectedGridFilter: {}
};

// console.log('-------------------initUserState', initUserState);

const filterReducer = function(state = initUserState, action) {
  switch (action.type) {

    case filterTypes.GRID_FILTER_APPLY:
      return { ...state,
        selectedGridFilter: get(action, 'payload', {})
      };
    break;

    case filterTypes.GRID_FILTER_CLEAR:
      return { ...state,
        ...initUserState
      };
    break;

    default:
      return state;
  }
};

export default filterReducer;
