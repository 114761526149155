const paginate = {
  total: 0,
  limit: 10,
  skip: 0
};

const getSchema = () => ({
  ...paginate
});

export {
  getSchema
}
