
export const GRID_FILTER_APPLY = 'FILTER/GRID/APPLY';
export const GRID_FILTER_CLEAR = 'FILTER/GRID/CLEAR';

// // DETAIL
// export const DETAIL_SUCCESS = 'COURT/DETAIL/SUCCESS';
// export const DETAIL_FAILURE = 'COURT/DETAIL/FAILURE';
// export const DETAIL_REQUEST = 'COURT/DETAIL/REQUEST';
// // CREATE
// export const CREATE_SUCCESS = 'COURT/CREATE/SUCCESS';
// export const CREATE_FAILURE = 'COURT/CREATE/FAILURE';
// export const CREATE_REQUEST = 'COURT/CREATE/REQUEST';
// // REMOVEs
// export const REMOVE_SUCCESS = 'COURT/REMOVE/SUCCESS';
// export const REMOVE_FAILURE = 'COURT/REMOVE/FAILURE';
// export const REMOVE_REQUEST = 'COURT/REMOVE/REQUEST';
