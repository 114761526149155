import {
  ERROR,
  WARNING,
  CLEAR,
  SUCCESS
} from './toastActionType';

const initialState = {
  message: '',
  show: false,
  error: false,
  type: 'warning'
};


export default (state = initialState, action = {}) => {
  switch(action.type) {
    case ERROR:
      return {...state, type: 'error', message: action.payload, show: true, error: true};
    case WARNING:
      return {...state, type: 'warning', message: action.payload, show: true, error: false};
    case SUCCESS:
      return {...state, type: 'success', message: action.payload, show: true, error: false};
    case CLEAR:
      const {type, ...others} = initialState;
      return {...state, ...others};
    default:
      return state;
  }
}
