import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {ConnectedRouter} from 'connected-react-router';
import RegisterServiceWorker from './app/rsworker';
import history from './app/history';
import store from './app/store';
import App from './app/index';

const rootEl = document.getElementById('app');
const render = () => {
	ReactDOM.render(
		<Provider store={store}>
			<ConnectedRouter history={history}>
				<App />
			</ConnectedRouter>
		</Provider>,
		rootEl
	);
};

render();
RegisterServiceWorker();

if(module.hot) {
  module.hot.accept('./', render);
}
