import {connectRouter, routerMiddleware} from 'connected-react-router';
import {composeWithDevTools} from 'redux-devtools-extension';
import {createStore, applyMiddleware} from 'redux';
import {createLogger} from 'redux-logger';
import thunk from 'redux-thunk';
import rootReducer from './reducer';
import history from './history';

const reducers = connectRouter(history)(rootReducer);

let middleWares = applyMiddleware(routerMiddleware(history), thunk);

if(process.env.NODE_ENV !== 'production') {
  middleWares = applyMiddleware(routerMiddleware(history), thunk, createLogger());
}

const store = createStore(reducers, {}, composeWithDevTools(middleWares));

if(module.hot) {
  module.hot.accept('./reducer', () => {
    store.replaceReducer(connectRouter(history)(rootReducer))
  });
}

export default store;