import React, {useRef, useState, useEffect} from 'react';
import CLogo from './images/logo_clipping.svg';
import XLogo from './images/logo_xprog.black.svg';
import ReactRotatingText from "react-rotating-text";
import './stylesheet/main.css';
let index = 0;

// const texts = [
//   "The good thoughts",
//   "depends how you",
//   "organize the information.",
//   "It's time to redesign",
//   "and be what people needs."
// ];

const texts = [
  "The good thoughts depend on how you organize the information.",
  "It's time to redesign our app and be what people needs.",
  // "Organizar a informação não é uma tarefa simples.",
  // "Estamos trabalhando nisso."
];

const Component = (props) => {
	return (
		<div className="main-container">
			<div className="main-maxwidth">
				<div className="main-topbar">
					<a href="http://www.xprog.com.br" target="_blank">
						<img src={XLogo} className="img-x-logo" />
					</a>
				</div>
				<div className="main-body">
					<p className="main-title">
						<ReactRotatingText items={texts} />
					</p>
				</div>
				<div className="main-bottom">
					<img src={CLogo} className="img-c-logo" />
				</div>
			</div>
		</div>
	)
}

export default Component;