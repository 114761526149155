import {combineReducers} from 'redux';
import userReducer from '../reducers/userReducer';
import articleReducer from '../reducers/articleReducer';
import filterReducer from '../reducers/filterReducer';
import toast from './toast/toastReducer';


const reducers = combineReducers({
  user: userReducer,
  article: articleReducer,
  filter: filterReducer,
  toast
});

export default reducers;
