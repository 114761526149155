import ReactGA from 'react-ga';
import * as authTypes from './actions/AuthActionTypes';
import * as userTypes from './actions/UserActionTypes';
import {getSchema} from '../app/paginateUtil';

const setUserOnce = (userName) => {
  const currentUserName = localStorage.getItem('name');
  if(!currentUserName || currentUserName !== userName) {
    ReactGA.set({user: userName});
  }
};

const initUserState = {
  logged: false,
  logging: false,
  user: {},
  list: {
    loading: false,
    data: [],
    index: getSchema()
  },
  detail: {
    loading: false,
    data: null
  }
};

const userReducer = function(state = initUserState, action){
  switch (action.type){

    // LOGIN
    case authTypes.LOGIN_REQUEST:
      return {...state, logging:true};
    case authTypes.LOGIN_SUCCESS:
      setUserOnce(action.payload.name);
      localStorage.setItem('auth-token', action.payload.token);
      localStorage.setItem('name', action.payload.user.firstname);
      ReactGA.event({
        category: 'Auth',
        action: 'User login',
        label: action.payload.user.firstname
      });
      return {...state, ...action.payload.user, user: action.payload.user, logged: true, logging:false};
    case authTypes.LOGIN_FAILURE:
      localStorage.removeItem('auth-token');
      localStorage.removeItem('name');
      return {...state, logged: false, logging:false};

    // LOGOUT
    case authTypes.TOKEN_FAILURE:
    case authTypes.LOGOUT_REQUEST:
      const currentUserName = localStorage.getItem('name');
      localStorage.removeItem('auth-token');
      localStorage.removeItem('name');
      ReactGA.event({
        category: 'Auth',
        action: 'User logout',
        label: currentUserName
      });
      return {...state, logged: false, user: {}};

    // TOKEN
    case authTypes.TOKEN_SUCCESS:
      setUserOnce(action.payload.name);
      localStorage.setItem('name', action.payload.user.firstname);
    return {
      ...state,
      ...action.payload,
      logged: true,
      logging: false
    };
    case authTypes.TOKEN_REQUEST:
    return {
      ...state,
      logged: false,
      logging: true
    };
    // LIST
    case userTypes.LIST_SUCCESS:
    return { ...state,
      list: {
        data: action.payload.items || [],
        index: action.payload.index,
        loading: false
      }
    };
    case userTypes.LIST_FAILURE:
    return {
      ...state,
      list: {
        ...initUserState.list
      }
    };
    case userTypes.LIST_REQUEST:
    return { ...state,
      list: {
        data: [],
        loading: true
      }
    };
    // DETAIL
    case userTypes.DETAIL_SUCCESS:
      return {
        ...state,
        detail: {
          data: {...action.payload},
          loading: false
        }
      };
    case userTypes.DETAIL_FAILURE:
    return {
      ...state,
      detail: {
        ...initUserState.detail
      }
    };
    case userTypes.DETAIL_REQUEST:
    return { ...state,
      detail: {
        data: null,
        loading: true
      }
    };

    default:
      return state;
  }
};

export default userReducer;
