import * as articleTypes from './actions/ArticleActionTypes';
import {getSchema} from '../app/paginateUtil';

const baseState = {
  list: {
    loading: false,
    data: [],
    index: getSchema()
  },
  detail: {
    loading: false,
    data: {}
  }
};

const initUserState = {
  articles: {
    ...baseState
  }
};

const rentalReducer = function(state = initUserState, action) {
  switch (action.type) {
    // LIST
    case articleTypes.LIST_SUCCESS:
      return { ...state,
        articles: {
          ...state.articles,
          list: {
            data: action.payload.items || [],
            index: action.payload.index,
            loading: false
          }
        }
      };
    case articleTypes.LIST_FAILURE:
      return { ...state,
        articles: {
          ...state.articles,
          list: {
            ...initUserState.articles.list
          }
        }
      };
    case articleTypes.LIST_REQUEST:
      return { ...state,
        articles: {
          ...state.articles,
          list: {
            ...state.articles.list,
            data: [],
            loading: true
          }
        }
      };
    // DETAIL
    case articleTypes.DETAIL_SUCCESS:
      return { ...state,
        articles: {
          ...state.articles,
          detail: {
            data: { ...action.payload
            },
            loading: false
          }
        }
      };
    case articleTypes.DETAIL_FAILURE:
      return { ...state,
        articles: {
          ...state.articles,
          detail: { ...initUserState.articles.detail
          }
        }
      };
    case articleTypes.DETAIL_REQUEST:
      return { ...state,
        articles: {
          ...state.articles,
          detail: {
            data: null,
            loading: true
          }
        }
      };

    default:
      return state;
  }
};

export default rentalReducer;
